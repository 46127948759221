import { Formik } from 'formik';
import { signIn } from 'next-auth/react';
import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styled from 'styled-components';
import Body from 'components/common/body';
import SimpleButton from 'components/common/button/simple';
import { TextInput } from 'components/form/input';
import { CREDENTIALS_PROVIDER } from 'constants/auth';
import { PROFILE_COMPLETION_URL } from 'constants/urls';
import useRequestPasswordReset from 'containers/profile/password/useRequestPasswordReset';
import { theme } from 'utils/theme';

const CredentialsSigninDiv = styled.div`
  margin-top: 60px;
  border-top: 1px solid ${theme.colors.gray300};
`;

const Form = styled.form`
  margin: 20px 0;
  width: 100%;
`;

interface CredentialsSigninProps {
  referralId?: string;
}

const CredentialsSignin = ({ referralId }: CredentialsSigninProps) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const {
    requestPasswordReset,
    data: requestPasswordResetData,
    loading: requestPasswordResetLoading,
  } = useRequestPasswordReset();

  return (
    <CredentialsSigninDiv>
      <Formik
        initialValues={{ username: '', password: '' }}
        validate={(values) => {
          let errors = {};
          if (!values.username) {
            errors = { ...errors, username: 'Please add your email' };
          }
          if (!values.password) {
            errors = { ...errors, password: 'Please add a strong password' };
          }

          return errors;
        }}
        onSubmit={async ({ username, password }, { setSubmitting }) => {
          setSubmitting(true);

          const recaptchaToken = await executeRecaptcha?.('CredentialsSignin');

          signIn(CREDENTIALS_PROVIDER, {
            referralId: referralId || '', // there was a weird issue where the provider would receive it as 'undefined'
            gRecaptchaToken: recaptchaToken,
            callbackUrl: `${PROFILE_COMPLETION_URL}?referralId=${referralId}`,
            username,
            password,
          });
        }}
      >
        {({ handleSubmit, values }) => (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <TextInput
              width="100%"
              id="username"
              label="Email"
              name="username"
              type="email"
              placeholder="frequent@traveler.com"
            />
            <TextInput
              width="100%"
              id="password"
              label="Password"
              name="password"
              type="password"
              placeholder="********"
            />
            <SimpleButton backgroundColor={theme.colors.gray900} width="100%">
              <Body color={theme.colors.white}>Continue with email and password</Body>
            </SimpleButton>
            <SimpleButton
              disabled={
                !!requestPasswordResetData?.requestPasswordReset || requestPasswordResetLoading
              }
              type="button"
              width="100%"
              backgroundColor={theme.colors.gray200}
              onClick={() => {
                if (values.username.length) {
                  requestPasswordReset(values.username);
                }
              }}
            >
              <Body centered color={theme.colors.black}>
                Forgot password
              </Body>
            </SimpleButton>
            {!!requestPasswordResetData?.requestPasswordReset && (
              <Body fullWidth>If the user exists, you&apos;ll receive a password reset email.</Body>
            )}
          </Form>
        )}
      </Formik>
    </CredentialsSigninDiv>
  );
};

export default CredentialsSignin;
