import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables,
} from 'graphql/generated';
import { REQUEST_PASSWORD_RESET } from './password.query';

const useRequestPasswordReset = () => {
  const [requestPasswordResetMutation, { data, loading, error }] = useMutation<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >(REQUEST_PASSWORD_RESET, {
    optimisticResponse: {
      __typename: 'Mutation',
      requestPasswordReset: {
        __typename: 'SimpleOK',
        ok: true,
      },
    },
  });

  const requestPasswordReset = useCallback(
    (username: string) => requestPasswordResetMutation({ variables: { username } }),
    [requestPasswordResetMutation],
  );

  return { requestPasswordReset, data, loading, error };
};

export default useRequestPasswordReset;
