import isWebview from 'is-ua-webview';
import Body from 'components/common/body';
import SimpleButton from 'components/common/button/simple';
import Heading from 'components/common/heading';
import Container from 'components/container';
import PublicLayout from 'components/layouts/Public';
import { NEXT_PUBLIC_APP_URL } from 'constants/env';
import { theme } from './theme';

interface WithWebviewChecksProps {
  userAgent?: string;
  redirectPath: string;
  children: React.ReactNode;
}

const WithWebviewChecks = ({ children, userAgent, redirectPath }: WithWebviewChecksProps) =>
  userAgent && !isWebview(userAgent) ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <PublicLayout title="Sign In">
      <Container centerContent py="20px" pylg="20px">
        <Heading level={300}>Please open this page in a browser</Heading>

        <SimpleButton
          onClick={() => {
            navigator.clipboard.writeText(`${NEXT_PUBLIC_APP_URL}${redirectPath}`);
          }}
          borderColor={theme.colors.gray300}
          width="240px"
        >
          <Body leftAlign>Copy link to clipboard</Body>
        </SimpleButton>
      </Container>
    </PublicLayout>
  );

export default WithWebviewChecks;
