import { ClientSafeProvider, signIn } from 'next-auth/react';
import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';
import Body from 'components/common/body';
import SimpleButton from 'components/common/button/simple';
import Heading from 'components/common/heading';
import { GOOGLE_AUTH_IMAGE } from 'constants/images';
import { PROFILE_COMPLETION_URL } from 'constants/urls';
import { theme } from 'utils/theme';
import { NextAuthProvider } from '../types';
import CredentialsSignin from './credentialsSignIn';

type SignInItemProps = Pick<ClientSafeProvider, 'id'> & { referralId?: string };

const SignInItem = ({ id, referralId }: SignInItemProps) => {
  switch (id) {
    case 'google':
      return (
        <SimpleButton
          onClick={() =>
            signIn(id, {
              referralId,
              callbackUrl: `${PROFILE_COMPLETION_URL}?referralId=${referralId}`,
            })
          }
          backgroundColor={theme.colors.gray900}
        >
          <Image src={GOOGLE_AUTH_IMAGE} alt="SignIn with Google" width={45} height={45} />
          <Body margin="0 0 0 10px" color={theme.colors.white}>
            Continue with Google
          </Body>
        </SimpleButton>
      );

    case 'credentials':
      return <CredentialsSignin referralId={referralId} />;

    default:
      return null;
  }
};

const OuterDiv = styled.div`
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 50px;
`;

export interface SignInWithProviderProps {
  providers: NextAuthProvider;
  referralId?: string;
}

const SignInWithProvider = ({ providers, referralId }: SignInWithProviderProps) => {
  return (
    <OuterDiv>
      <Heading level={300} margin="0 0 10px 0">
        Sign In
      </Heading>
      {Object.values(providers)
        .sort(({ id }) => (id === 'credentials' ? 1 : -1)) // just to keep credentials last
        .map((provider) => (
          <SignInItem key={provider.id} {...provider} referralId={referralId} />
        ))}
    </OuterDiv>
  );
};

export default SignInWithProvider;
