// Errors
export enum AuthError {
  CREDENTIALS_NOT_PROVIDED = 'authError0',
  ACCOUNT_WITH_OTHER_PROVIDER_ALREADY_EXISTS = 'authError1',
  WRONG_USERNAME_OR_PASSWORD_ERROR = 'authError2',
  REFERRAL_REQUIRED_ERROR = 'authError3',
}

export const getTextForError = (error: AuthError): string => {
  switch (error) {
    case AuthError.ACCOUNT_WITH_OTHER_PROVIDER_ALREADY_EXISTS:
      return 'Are you trying to log in with Google instead?'; // TODO: change when adding more providers
    case AuthError.CREDENTIALS_NOT_PROVIDED:
      return 'Please provide your email and password.';
    case AuthError.REFERRAL_REQUIRED_ERROR:
      return 'Idealist is referral only for now.';
    case AuthError.WRONG_USERNAME_OR_PASSWORD_ERROR:
      return 'The account might not exist or the password is wrong.';
    default:
      return 'An error occured. Please try again or contact us.';
  }
};

export const CREDENTIALS_PROVIDER = 'credentials';

export const BCRYPT_PASSWORD_SALT_ROUNDS = 11;
